import Taytu from "../images/Taytu.jpg";
import Timint from "../images/Eritriea.jpg";
import Educationslogan from "../images/Educationcquot.jpg";
import Bethelhem from "../images/tech.jpg";
import Rediet from "../images/Proffesor.jpg";
import Techslogan from "../images/Womens.jpg";

export const blog = [
  {
    id: 1,
    type: "By Eldana Assefa",
    date: "",
    com: "",
    title:
      "Etege Taitu’s Contribution to Ethiopian History and Its Impact on My Personal Journey",
    desc: `
      <p><strong>Etege Taitu Betul</strong>, renowned for her strength and leadership, was more than just the consort of Emperor Menelik II; she was a visionary and a key figure in Ethiopia's resistance against colonialism. Her role in the Battle of Adwa and her influence on Ethiopia’s political and social landscape showcase her as a symbol of resilience and courage. Her story is a source of inspiration for Ethiopia and for me personally.</p>
      <h3>Etege Taitu’s Life Journey and Contributions to Ethiopia</h3>
      <p>Etege Taitu Betul, born in the late 19th century in Wollo, Ethiopia, came from a noble background. As a member of the Amhara aristocracy, her father, Ras Betul Haile Maryam, was a regional governor, and Taitu developed strong leadership qualities and political acumen from an early age. These traits would later become essential in her role as Empress.</p>
      <p>Taitu married Emperor Menelik II in 1883, and their partnership was marked by mutual respect and collaboration. Although Menelik was the reigning monarch, Taitu was not content to remain in the background. She wielded considerable influence in the empire's affairs, often advising Menelik and participating in decision-making processes. Her intellect and strong will earned her the Emperor's trust and the admiration of many.</p>
      <p>Beyond her role as Empress, Taitu was deeply involved in shaping Ethiopia’s political landscape, particularly in diplomacy, economic development, and social welfare. Her commitment to education and health initiatives improved the lives of countless Ethiopians. Perhaps her most notable contribution came during the Battle of Adwa in 1896, where her leadership and resourcefulness played a crucial role in Ethiopia’s decisive victory against Italian colonial forces.</p>
      <p>Despite her achievements, Etege Taitu faced significant challenges, particularly as a woman in a patriarchal society. Balancing her responsibilities as Empress with her personal aspirations required immense strength and resilience, but she met these challenges head-on, leaving an indelible mark on Ethiopian history.</p>
      <h3>How Etege Taitu’s Journey Guided Me</h3>
      <p>I first encountered her story in history class, and my curiosity led me to delve deeper into her life. What I found was a woman who embodied courage, intelligence, and unwavering determination-qualities that I deeply admire. Her leadership during the Battle of Adwa exemplified strategic thinking and steadfast patriotism, and her resilience in the face of societal pressures was a powerful reminder of what is possible when one is determined to overcome obstacles.</p>
      <p>Etege Taitu’s legacy has been a guiding light for me as I navigate my own aspirations, particularly in leadership. Her example fueled my passion for empowering other women, which led me to establish the 'Encourage Her' club. This initiative supports young women in pursuing leadership opportunities, following their passions, and making meaningful contributions to their communities. In many ways, Etege Taitu’s story inspired me to create a local community club that fosters sisterhood and shared purpose, much like she did during her time. Her life and achievements continue to resonate with me, reminding me that the path to greatness is often fraught with challenges, but with resilience and determination, those challenges can be overcome. By sharing her story, I hope to contribute to the ongoing recognition and celebration of Ethiopian women’s accomplishments, and to inspire future generations to pursue their dreams with the same zeal and commitment that Etege Taitu embodied.</p>
      <p><a href="https://theafricanhistory.com/1192"><strong>Reference:</strong> </a></p>
      `,
    cover: Taytu,
  },
  {
    id: 2,
    type: "By Eldana Assefa",
    date: "",
    com: "",
    title:
      "Rediet Abebe, [Success Stories of Ethiopian Women in Tech and How They Inspire Me]",
    desc: `
    <p><strong>Rediet Abebe</strong>, is an Ethiopian woman known for her work in using algorithms and artificial intelligence to tackle social issues, particularly in developing regions. Born and raised in Addis Ababa, Ethiopia, Rediet moved to the United States for higher education.</p>
    <p>She completed her undergraduate studies in mathematics at Harvard University, followed by a Master's degree in Applied Mathematics from the same institution. Rediet then pursued her Ph.D. in Computer Science at Cornell University, focusing on computational and algorithmic techniques to address problems related to poverty, inequality, and social good. She is the first Black woman to earn a Ph.D. in Computer Science from Cornell University.</p>
    <p>Rediet has been a part of several influential projects and research initiatives. She co-founded the Mechanism Design for Social Good (MD4SG) initiative, which brings together researchers and practitioners to develop solutions for socio-economic problems. Her work has received numerous accolades, including being named a 2020-2021 Junior Fellow at the Harvard Society of Fellows and receiving the prestigious Microsoft Research Dissertation Grant. She is also a co-founder of Black in AI.</p>
    <h3>How Rediet Abebe Inspires Me</h3>
    <p>In many ways, I find Rediet Abebe's journey and achievements incredibly inspiring. The fact that she is from Ethiopia and has excelled in a highly competitive field like computer science in the United States speaks volumes. Her story shows that geographical and socio-economic barriers can be conquered through diligence.</p>
    <p>Moreover, as an advocate for diversity in technology, her efforts to support underrepresented groups are inspiring in their own right. She embodies the spirit of creating an inclusive environment that allows everyone a chance to succeed, which aligns perfectly with the mission of She-Debugs, the club I founded in my local community. This club empowers young women in tech fields by providing tutorials and peer-to-peer sharing of experiences. Rediet Abebe’s focus on using her technical skills to drive change around social issues is something close to my heart. She highlights how technology can be a powerful tool in solving some of the world's most pressing problems, including poverty and inequality. This motivates me to think about how I can use my skills and resources to improve my local environment.</p>
    <p>By co-founding MD4SG, Rediet has emphasized the importance of collaboration and leadership in driving social change. The way she brings together people from diverse backgrounds to work towards common goals is truly inspirational. It has motivated me to foster teamwork and community engagement in my own projects. Rediet inspires me through her academic achievements and recognition in the tech community to strive for excellence in my own educational and professional pursuits. Her story reminds me that with hard work, passion, and a commitment to lifelong learning, anything is possible.</p>
    <p><a href="https://en.wikipedia.org/wiki/Rediet_Abebe"><strong>Reference:</strong></a></p>

    `,
    cover: Rediet,
  },
  {
    id: 3,
    type: "By Eldana Assefa",
    date: "",
    com: "",
    title:
      "Betelhem Dessie, [Success Stories of Ethiopian Women in Tech and How They Inspire Me]",
    desc: `
    <p><strong>Betelhem Dessie</strong>, Betelhem Dessie is one of the pioneering forces in Ethiopia's tech landscape, often referred to as "the youngest pioneer in Ethiopia's fast-emerging tech scene." Born in Harar, Ethiopia, Betelhem showed an early interest in technology, helping her father in his electronics shop at a very young age. This exposure sparked a passion that drove her to the forefront of Ethiopian tech.</p>
    <p>At twelve, Betelhem was recognized by the Ethiopian government for her contributions to the community and was hired as a developer by the Ethiopian Information Network Security Agency (INSA). Later, she founded and became the CEO of Anyone Can Code (ACC), a program in collaboration with iCog Labs—the first Artificial Intelligence and Robotics Lab in Ethiopia. Through ACC, Betelhem empowers children between the ages of six and thirteen with 21st-century skills, such as coding, robotics, and AI, aiming to raise a new generation of problem solvers and innovators.</p>
    <p>Betelhem’s impact extends beyond ACC. She actively participates in the Solve IT program at iCog Labs, working with young innovators to develop tech-based solutions for local problems. She has also trained forty girls through the 'Girls Can Code' project, supported by the United States Embassy. Betelhem is committed to empowering youth, particularly girls, in the tech sector by creating role models and mentors for aspiring technologists in Ethiopia.</p>
    <h3>How Betelhem Dessie Inspires Me</h3>
    <p>The story of Betelhem Dessie has been a true inspiration for me. Her journey from a childhood passion for technology to making a significant impact in a male-dominated field motivates me to encourage young girls in my clubs to start exploring tech early on. Betelhem has carved out a place for herself and other women in tech by overcoming stereotypes and barriers that often deter women from pursuing this career.</p>
    <p>Her achievements inspire me to keep advocating for equality in tech through my clubs, Encourage Her and She-Debugs. Betelhem’s work with Anyone Can Code shows that her impact goes beyond personal success; she’s empowering a new generation of Ethiopian youth. Her dedication to education and skill development aligns with my own belief in the power of education and giving back to the community through my clubs.</p>
    <p>Starting with Betelhem’s leadership at iCog Labs and her entrepreneurial spirit with ACC, she highlights the importance of innovative thinking and leadership. Her commitment to using technology for social good resonates deeply with me and strengthens my resolve to use technology to make a positive difference in societ.</p>
    <p><a href="https://en.wikipedia.org/wiki/Betelhem_Dessie"><strong>Reference:</strong> </a></p>

    `,
    cover: Bethelhem,
  },
  {
    id: 4,
    type: "By Eldana Assefa",
    date: "",
    com: "",
    title:
      " Timnit Gebru, [Success Stories of Ethiopian Women in Tech and How They Inspire Me]",
    desc: `
    <p><strong>Timnit Gebru</strong>, Timnit Gebru is an Eritrean Ethiopian-born known in the Artificial Intelligence (AI) community for her strong advocacy for diversity and ethics in technology. Born in Addis Ababa, Ethiopia, Timnit moved to the United States at the age of 15. She earned her undergraduate and master degree in electrical engineering from Stanford University and later completed her Ph.D. in computer vision from the same institution. Her academic and professional career is marked by a deep commitment to ensuring that AI serves all communities equitably.</p>
    <p>Timnit co-founded Black in AI with Rediet Abebe to increase the representation and inclusion of Black people in AI. She has worked with several prominent tech companies, including Google, where she co-led the Ethical AI team. Much of Timnit's work focuses on the societal impact of AI, particularly addressing biases in machine learning algorithms and the need for inclusive datasets.</p>
    <h3>How Timnit Gebru Inspires Me</h3>
    <p>Timnit Gebru’s advocacy for diversity and inclusion is deeply inspiring. Through Black in AI and other initiatives, she has created spaces and opportunities for underrepresented groups, emphasizing the importance of building an inclusive and fair tech industry. This mission aligns perfectly with the core goals my club, She-Debugs.</p>
    <p>Her unwavering commitment to ethical AI reminds me of the responsibility that comes with technological progress. Timnit’s work on identifying and addressing biases in AI systems encourages me to consider the broader implications of the technologies I work on.</p>
    <p>Timnit's refusal to remain silent in the face of injustice is a guiding principle for me. Her academic achievements and leadership in technology demonstrate that women can excel and lead, motivating me to continue supporting other women on their journey in tech.</p>
    <p><a href="https://en.wikipedia.org/wiki/Timnit_Gebru"><strong>Reference:</strong> </a></p>

    `,
    cover: Timint,
  },
  {
    id: 5,
    type: "By Eldana Assefa",
    date: "",
    com: "",
    title: "A Guide For Young Women To Ignite Their Tech Skills",
    desc: `<p>Technology has become the way of life in this fast-moving digital world. In a lot of sectors, tech-driven innovations define our world. Keeping in view the immense potential of technology, young women should be prepared to embrace this dynamic field and take up their respective roles. Historically, the tech industry has been a male dominated area; this thereby widened the wide gap in gender. Things are, however, dramatically changing with more women joining the industry. We, in this way, open the way for an inclusive and innovative society. Acquiring tech skills will offer many benefits to young women. It helps these women become critical thinkers, solvers of problems, and innovators. Great career opportunities open for themselves, and they are able to contribute with meaning to the communities where they come from. This guide is thus provided to inspire and equip young women to fire up their tech potential and get into an exciting journey of discovery and growth. It also outlines the steps they should consider taking to start their tech path.</p>

            <h2>Discover Your Tech Interest</h2>
            <p>Every girl has unique interests and strengths. To turn your tech potential into reality, focus on areas that excite and challenge you. Here are some popular tech fields to consider:</p>
            <ul>
              <li><strong>Coding:</strong> Learn programming languages like Python, JavaScript, or HTML to create games, websites, or apps.</li>
              <li><strong>Digital Literacy:</strong> Master the basics of navigating the digital world, including online safety, critical thinking, and information evaluation.</li>
              <li><strong>Data Analysis:</strong> Use tools like Excel, Python, or SQL to uncover patterns and insights from data.</li>
              <li><strong>Creativity and Design:</strong> Explore graphic design, UI/UX design, or digital media creation to enhance your creative skills.</li>
              <li><strong>Cybersecurity:</strong> Understand key concepts of cybersecurity to protect yourself and others from online threats.</li>
            </ul>
            <p>These examples are just a starting point. Explore areas that align with your passions and goals.</p>

            <h2>Places to Learn the Skills</h2>
            <p>The digital age offers numerous resources for learning new skills. Here are some flexible options:</p>
            <ul>
              <li><strong>Online Courses:</strong> Structured learning paths on platforms like Coursera, edX, and Udemy.</li>
              <li><strong>Coding Bootcamps:</strong> Intensive programs that provide hands-on coding experience.</li>
              <li><strong>YouTube Tutorials:</strong> Free, step-by-step video tutorials on various tech topics.</li>
              <li><strong>Online Communities:</strong> Join forums and communities to connect with other learners and seek support.</li>
            </ul>

            <h2>Building a Supportive Community</h2>
            <p>Surrounding yourself with like-minded individuals can make a significant difference in your tech journey. Join online forums, attend tech meetups, or participate in coding challenges to network with other women in tech. Sharing experiences, seeking advice, and collaborating on projects can accelerate your learning.</p>
            <p>Connecting with successful women in the tech industry can also be highly motivating. Reach out to them, follow their stories on social media, and learn from their experiences.</p>

            <h2>Balancing Tech with Other Interests</h2>
            <p>While pursuing tech goals, it’s important to maintain a balanced lifestyle. Allocate time for hobbies, friends, family, and self-care. A well-rounded life helps keep you motivated and prevents burnout.</p>

            <h2>Realizing It’s A Life-Long Journey</h2>
            <p>Technology is constantly evolving, so staying curious and embracing a growth mindset is essential. Continuously challenge yourself to learn and adapt to new trends. This approach will help you keep up with the field and reach your full potential.</p>
            <p><a href="https://www.geeksforgeeks.org/7-best-tips-to-learn-and-master-a-new-technology/"><strong>Reference:</strong>  </a></p>

            `,
    cover: Techslogan,
  },
  {
    id: 6,
    type: "By Eldana Assefa",
    date: "",
    com: "",
    title: "Empowering Girls Through Education In Ethiopia",
    desc: `
<p>Ethiopia has recorded tremendous progress in recent years in raising girls' participation in education. Amongst these, one of the greatest achievement has been the empowerment of girls with the achievement of gender equality.This blog post gives some of the highlights of the achievements and initiatives that need to be taken further in enhancing the participation of Ethiopian girls in education.<p/>
<p>Quality education is a fundamental right and, together with being an asset in the promotion of young women. In Ethiopia, specifically, UNICEF Ethiopia, in its fact sheet on girls' education November 2018, points out that the nation has registered remarkable progress in boosting the enrollment of girls at the primary level; that's notwithstanding net primary enrollment rates rising from 51 % in 2003/04 to 95 % in 2016/17. This clearly shows a high level of attention to policies designed to reduce the gender gap within the education sector.<p/>
<p>The same research, however, shows that only 53% of girls complete primary school, 25% of the secondary school-aged girls attend secondary school, with an estimated 10% going on to enroll in college. There is a decline in sustaining girls' attendance in higher education.<p/>
<p>The research also articulates the struggles of girls to pursue their education in school.
These include poverty, biases based on gender, early marriage, and a general negative social setting that affects girls' education by preventing commencing and completing schooling. This also includes the insecure and unsupportive learning school environment, which sometimes has issues related to gender-based violence. These challenges are likely to be more severe in the case of girls from vulnerable groups such as pastoralists, refugees, and those living in poverty.<p/>
<p>UNICEF Ethiopia is addressing these challenges with girls' empowerment in Ethiopia through various types of interventions, from providing safe school environments and life skills training to prevention and response to gender-based violence, improved sanitation facilities, and gender equity in education. There is special attention to girls in marginalized communities like pastoralists.<p/>
<p>When I reflect on the research, the progress so far made by Ethiopia in girls' education inspires and motivates me.This huge step towards increasing the enrollment rate is very commendable, but the challenges that remain remind one that the journey is far from over. Every girl deserves a chance to follow her dreams, unlock her potential, and be all she can through education. The decline of the girls participation statistics as they progress up the educational system is, however, a worrying issue.This realizes the imperative need for continued efforts in the creation of supportive, safe, and inclusive environments that enable girls to thrive academically. As a strong believer in the power of the girls' education, I feel we ought not to stop at getting them into schools but rather ensure retention and success.<p/>
<p>In fact, with efforts like those of UNICEF Ethiopia, it will be possible to create a future in which every girl, irrespective of her background, gets a chance to learn, grow, and contribute to society. It will take the collective effort of families, communities, educators, and policymakers to struggle against injurious norms, support girls in every stage of their education, and move for policies that clear a path to success for girls.<p/>
<p>This blog keeps reminding me again and again why it is that I am committed to the issue. The education and empowerment of girls in Ethiopia are matters of number or policy; they are about changing lives, giving each girl a chance to shine. This goes quite well with the vision of my club, Encourage Her, in its actions supporting and inspiring girls within the local community to pursue their education and reach their potential.<p/>
<p><a href="https://www.unicef.org/ethiopia/reports/unicef-factsheet-girls-education"><strong>Reference:</strong> </a></p>
   
`,
    cover: Educationslogan,
  },
];
